export const locationRoutes = [
  {
    id: 0,
    name: "Warszawa",
    text: "Warszawa",
    path: "/warszawa",
    position: { lat: 52.231695715731426, lng: 21.006629517085777 },
    zoom: 12,
  },
  {
    id: 3,
    name: "Kraków",
    text: "Kraków",
    path: "/krakow",
    position: { lat: 50.0584836784621, lng: 19.943840523840432 },
    zoom: 12,
  },
  {
    id: 27,
    name: "Łódź",
    text: "Łódź",
    path: "/lodz",
    position: { lat: 51.77215291422746, lng: 19.452430396708863 },
    zoom: 11,
  },
  {
    id: 2,
    name: "Gdańsk",
    text: "Gdańsk",
    path: "/gdansk",
    position: { lat: 54.37387606133528, lng: 18.628048314019264 },
    zoom: 12,
  },
  {
    id: 44,
    name: "Częstochowa",
    text: "Częstochowa",
    path: "/czestochowa",
    position: { lat: 50.80720146949798, lng: 19.11700036379876 },
    zoom: 13,
  },

  {
    id: 29,
    name: "Rzeszów",
    text: "Rzeszów",
    path: "/rzeszow",
    position: { lat: 50.040187985065174, lng: 22.00037957145782 },
    zoom: 12,
  },
  {
    id: 5,
    name: "Toruń",
    text: "Toruń",
    path: "/torun",
    position: { lat: 53.00658116489341, lng: 18.615904564503257 },
    zoom: 12,
  },

  {
    id: 1,
    name: "Katowice",
    text: "Katowice",
    path: "/katowice",
    position: { lat: 50.25756331475167, lng: 19.01954978760996 },
    zoom: 13,
  },
  {
    id: 9,
    name: "Szczecin",
    text: "Szczecin",
    path: "/szczecin",
    position: { lat: 53.43267411691495, lng: 14.548073836644463 },
    zoom: 12,
  },
  {
    id: 33,
    name: "Zakopane",
    text: "Zakopane",
    path: "/zakopane",
    position: { lat: 49.272155602224025, lng: 19.969347357076483 },
    zoom: 12,
  },
  {
    id: 45,
    name: "Bydgoszcz",
    text: "Bydgoszcz",
    path: "/bydgoszcz",
    position: { lat: 53.12432960747762, lng: 18.01327782946127 },
    zoom: 13,
  },
  {
    id: 26,
    name: "Kielce",
    text: "Kielce",
    path: "/kielce",
    position: { lat: 50.861812946766555, lng: 20.631088379427016 },
    zoom: 12,
  },
  {
    id: 4,
    name: "Wrocław",
    text: "Wrocław",
    path: "/wroclaw",
    position: { lat: 51.11027936926381, lng: 17.03400277999203 },
    zoom: 12,
  },









  {
    id: 6,
    name: "Hel",
    text: "Hel",
    path: "/hel",
    position: { lat: 54.60495387796961, lng: 18.803091466964226 },
    zoom: 16,
  },
  {
    id: 7,
    name: "Wąbrzeźno",
    text: "Wąbrzeźno",
    path: "/wabrzezno",
    position: { lat: 53.280654701062225, lng: 18.947614644522613 },
    zoom: 13,
  },
  {
    id: 8,
    name: "Włocławek",
    text: "Włocławek",
    path: "/wloclawek",
    position: { lat: 52.65376311677585, lng: 19.06658172082318 },
    zoom: 13,
  },

  {
    id: 10,
    name: "Biłgoraj",
    text: "Biłgoraj",
    path: "/bilgoraj",
    position: { lat: 50.534727593129325, lng: 22.71973486215169 },
    zoom: 14,
  },

  {
    id: 12,
    name: "Szczebrzeszyn",
    text: "Szczebrzeszyn",
    path: "/szczebrzeszyn",
    position: { lat: 50.69698564832163, lng: 22.983751082749947 },
    zoom: 14,
  },

  {
    id: 13,
    name: "Gniezno",
    text: "Gniezno",
    path: "/gniezno",
    position: { lat: 52.53407010364664, lng: 17.593947682541728 },
    zoom: 14,
  },
  {
    id: 14,
    name: "Poznań",
    text: "Poznań",
    path: "/poznan",
    position: { lat: 52.40575525501248, lng: 16.943370025631335 },
    zoom: 11,
  },
  {
    id: 15,
    name: "Radzionków",
    text: "Radzionków",
    path: "/radzionkow",
    position: { lat: 50.39837334662003, lng: 18.899936645777437 },
    zoom: 14,
  },
  {
    id: 16,
    name: "Pyskowice",
    text: "Pyskowice",
    path: "/pyskowice",
    position: { lat: 50.397707637284036, lng: 18.62823004819742 },
    zoom: 15,
  },
  {
    id: 17,
    name: "Białystok",
    text: "Białystok",
    path: "/bialystok",
    position: { lat: 53.1323782896239, lng: 23.13455198083633 },

    zoom: 13,
  },
  {
    id: 18,
    name: "Skawina",
    text: "Skawina",
    path: "/skawina",
    position: { lat: 49.97500411567001, lng: 19.82844388182953 },
    zoom: 15,
  },
  {
    id: 19,
    name: "Konin",
    text: "Konin",
    path: "/konin",
    position: { lat: 52.22728888658325, lng: 18.262367233555757 },
    zoom: 12,
  },
  {
    id: 20,
    name: "Września",
    text: "Września",
    path: "/wrzesnia",
    position: { lat: 52.32329561045463, lng: 17.566204927784227 },
    zoom: 14,
  },

  {
    id: 21,
    name: "Płońsk",
    text: "Płońsk",
    path: "/plonsk",
    position: { lat: 52.619419839884934, lng: 20.37849646879809 },
    zoom: 11,
  },
  {
    id: 22,
    name: "Kamionki",
    text: "Kamionki",
    path: "/kamionki",
    position: { lat: 52.2855365, lng: 16.9841217 },
    zoom: 13,
  },
  {
    id: 23,
    name: "Pabianice",
    text: "Pabianice",
    path: "/pabianice",
    position: { lat: 51.66054259777352, lng: 19.349116974381893 },

    zoom: 13,
  },
  {
    id: 24,
    name: "Władysławowo",
    text: "Władysławowo",
    path: "/wladyslawowo",
    position: { lat: 54.79046186708976, lng: 18.40293011301815 },
    zoom: 14,
  },
  {
    id: 25,
    name: "Zamość",
    text: "Zamość",
    path: "/zamosc",
    position: { lat: 50.721266563365056, lng: 23.25589341123412 },
    zoom: 14,
  },
  {
    id: 28,
    name: "Wielbark",
    text: "Wielbark",
    path: "/wielbark",
    position: { lat: 53.39806758313509, lng: 20.945164630949915 },
    zoom: 15,
  },
  {
    id: 30,
    name: "Józefów",
    text: "Józefów",
    path: "/jozefow",
    position: { lat: 52.14475742921211, lng: 21.226409451387198 },
    zoom: 13,
  },
  {
    id: 31,
    name: "Karpacz",
    text: "Karpacz",
    path: "/karpacz",
    position: { lat: 50.772106071822876, lng: 15.749113741501983 },
    zoom: 14,
  },
  {
    id: 32,
    name: "Wadowice",
    text: "Wadowice",
    path: "/wadowice",
    position: { lat: 49.88200343573371, lng: 19.492609138760816 },
    zoom: 15,
  },
  {
    id: 34,
    name: "Radzymin",
    text: "Radzymin",
    path: "/radzymin",
    position: { lat: 52.41561730926621, lng: 21.178792333582138 },
    zoom: 13,
  },
  {
    id: 35,
    name: "Olsztyn",
    text: "Olsztyn",
    path: "/olsztyn",
    position: { lat: 53.75995151927914, lng: 20.48148931750457 },
    zoom: 12,
  },
  {
    id: 36,
    name: "Radom",
    text: "Radom",
    path: "/radom",
    position: { lat: 51.39854632468584, lng: 21.149851563130728 },
    zoom: 13,
  },
  {
    id: 37,
    name: "Elbląg",
    text: "Elbląg",
    path: "/elblag",
    position: { lat: 54.15476395823125, lng: 19.402421954968126 },
    zoom: 13,
  },

  {
    id: 38,
    name: "Bari",
    text: "Bari",
    path: "/bari",
    position: { lat: 41.111865185716155, lng: 16.867314274166016 },
    zoom: 14,
  },
  {
    id: 39,
    name: "Marki",
    text: "Marki",
    path: "/marki",
    position: { lat: 52.306088655350095, lng: 21.09101375225697 },
    zoom: 13,
  },
  {
    id: 40,
    name: "Budapeszt",
    text: "Budapeszt",
    path: "/budapeszt",
    position: { lat: 47.48434368781103, lng: 19.05544799258718 },
    zoom: 13,
  },
  {
    id: 41,
    name: "Berlin",
    text: "Berlin",
    path: "/berlin",
    position: { lat: 52.51578888944621, lng: 13.402378510728072 },
    zoom: 11,
  },
  {
    id: 42,
    name: "Miami",
    text: "Miami",
    path: "/miami",
    position: { lat: 25.791099559412793, lng: -80.21244751798125 },
    zoom: 11,
  },
  {
    id: 43,
    name: "Key West",
    text: "Key West",
    path: "/key-west",
    position: { lat: 24.55383992583167, lng: -81.78071955898969 },
    zoom: 14,
  },



];
